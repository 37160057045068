'use client';

import FullPageError from 'app/components/Error/FullPageError';
import { useLogException } from 'app/hooks/useLogException';
import { useTranslations } from 'next-intl';
export default function ErrorPage({ error }: { error: Error }) {
  useLogException(error);

  const t = useTranslations();

  return (
    <FullPageError
      title={t('ErrorPage.genericTitle')}
      message={t('ErrorPage.genericMessage')}
    />
  );
}
